import React, { useState, useEffect} from 'react'
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import { images } from '../../constants';

import './Header.scss';

const scaleVariants = {
    whileInView:{
        scale:[0,1],
        opacity: [0,1],
        tansition:{
            duration:1, 
            ease:'easeInOut'
        }
    }
}



const Header = () => {

    var max = 26;
    var rand1 =  1 + Math.floor((Math.random() * (max)));
    var rand2 =  1 + Math.floor((Math.random() * (max)));
    var rand3 =  1 + Math.floor((Math.random() * (max)));

    const [rndEmoji, setRndEmoji] = useState([]);
    const [skills, setSkills] = useState([]);
    const [skillsArray, setSkillsArray] = useState( [rand1, rand2, rand3]);
    
    const EmojiFunc = () =>{
        var max = 5;
        var rand =  1 + Math.floor((Math.random() * (max)));
        switch(rand) {
            case 1:
                setRndEmoji('👋')
              break;
            case 2:
                setRndEmoji('🖖')
              break;
            case 3:
                setRndEmoji('✋')
            break;
            case 4:
                setRndEmoji('🤙')
            break;            
            case 5:
                setRndEmoji('👍')
            break; 
            
            default:
                setRndEmoji('🖖')
          }
    }

    /*const SkillsArrayFunc = () =>{
        var max = 26;
        var rand1 =  1 + Math.floor((Math.random() * (max)));
        var rand2 =  1 + Math.floor((Math.random() * (max)));
        var rand3 =  1 + Math.floor((Math.random() * (max)));

        setSkillsArray([rand1, rand2, rand3]);
    }*/


    useEffect(() => {

        EmojiFunc();

        const skillsQuery = '*[_type =="skills"]';
        client.fetch(skillsQuery)
        .then((data) => {

            setSkills(data.filter(skill => skillsArray.includes(skill.id)));
        })
    }, [])


    return (
        <div className="app__header app__flex">

            <motion.div
                whileInView={{ x:[ -100, 0 ], opacity: [ 0,1 ] }}
                transition={{ duration: 0.5 }}
                className="app__header-info"
            >




                {/* PRODUCT OWNER NOTE:
                        Could we show website code
                        on the website itself?
                        ... very Meta...
                        Is that even possible?
                    DEV NOTE: 
                        Anything is posssible...
                */ }
                <div className="app__header-badge">
                    <div className="badge-cmp app__flex">
                        <div className="badge-cmp-sub">
                            <span>{rndEmoji}</span>
                            <div>
                                <p className="p-text"> Hello World! I'm</p>
                                <h1 className="head-text">Nicolas</h1>

                            </div>
                        </div>


                        <motion.div
                            whileInView={{ opacity: [ 0,1 ] }}
                            transition={{ duration: 0.5, delayChildren: 0.5 }}
                            className="app__header-img-small"
                        >
                            <img src={images.profile} alt="profile_img"/>
                        </motion.div>

                    </div>
                    <div className="tag-cmp app__flex">
                        <p className="p-text">Fullstack Developer</p>
                    </div>
                </div>
            </motion.div>

            <motion.div
                whileInView={{ opacity: [ 0,1 ] }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className="app__header-img"
            >
                <img src={images.profile} alt="profile_bg" />
                <motion.img
                    whileInView={{ scale: [ 0,1 ] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}

                    src={images.circle}
                    alt="profile_circle"
                    className="overlay_circle"
                />
            </motion.div>

            <motion.div
                variant={scaleVariants}
                whileInView={scaleVariants.whileInView}
                className="app__header-circles"
            >
                {skills.map((skill) =>(
                    <div className="circle-cmp app__flex" key={skills.id}>
                        <img src={urlFor(skill.icon)} alt= "circle"/>
                    </div>
                ))}
                
                {/*{[images.html, images.react, images.python].map((circle, index) =>(
                    <div className="circle-cmp app__flex" key={`circle-${index}`}>
                        <img src={circle} alt= "circle"/>
                    </div>
                ))}*/}
            </motion.div>

        </div>
    )
}

export default AppWrap(Header, 'home', 'home');
