import React, {useState, useEffect} from 'react';

import { motion } from 'framer-motion';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'; 

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Testimonials.scss';

const Testimonials = () => {

    const [brands, setBrands] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleClick = (index) =>{
        setCurrentIndex(index);
    }
    useEffect(() => {
        const brandsQuery = '*[_type =="brands"]';
        const testQuery = '*[_type =="testimonials"]';

        client.fetch(brandsQuery)
            .then((data) => {
                setBrands(data);
            })
        client.fetch(testQuery)
            .then((data) => {
                setTestimonials(data);
            })
    }, [])

    const test= testimonials[currentIndex];

    return (
        <div>
            {testimonials.length &&(

                
                <div>

                    <div className="app__testimonial-item app__flex">
                        <a href={test.link}><img src={urlFor(test.imageurl)} alt="testimonial"/></a>
                        <div className="app__testimonial-content">
                            <p className="p-text"> {test.feedback}</p>
                            <a href={test.link}>
                                <div>
                                    <h4 className="bold-text"> {test.name}</h4>
                                    <h5 className="p-text"> {test.jobtitle}</h5>
                                    <h5 className="p-text"> @ {test.company}</h5>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="app__testimonial-btns app__flex">
                        <div className="app__flex" onClick={ () => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1) }>
                            <HiChevronLeft />
                        </div>
                        <div className="app__flex" onClick={ () => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1) } >
                            <HiChevronRight />
                        </div>
                    </div>
                </div>
            )}

            <div className="app__testimonial-brands app__flex">
                {brands.map((brand) => (
                    <motion.div
                        whileInview={{opacity: [0,1]}}
                        duration={{duration:0.5, type: 'tween'}}
                        key={brand.id}
                    >
                        <img src= {urlFor(brand.imgUrl)} alt={brand.name} />
                    </motion.div>
                ))}
            </div>

        </div>
    )
}


export default AppWrap(
    MotionWrap(Testimonials, 'app__testimonials'),
    'testimonials',
    "app__primarybg"
)