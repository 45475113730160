import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';



import { urlFor, client} from '../../client';



const About = () => {

    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        const query = '*[_type =="abouts"]'
        client.fetch(query)
            .then((data) => {
                setAbouts(data);
            })
    }, [])


    return (
        <div >

            <div className="app__about-text">


                <h2 className="head-text">
                    ✔️ Quality Development<br/>
                    ✔️ Quality Design<br/>
                    ✔️ <span> Good Business</span>
                    {/*
                    ✔️ Intuitive UI/UX Design<br/>
                    ✔️ Secure/Structured Data<br/>
                    ✔️ <span> Good Business</span><br/>
                     */}
                    
                </h2>
            </div>
            <div className="app__profiles">
                {abouts.sort((a,b) => a.order - b.order).map((about, index) =>
                    <motion.div
                        whileInView={{ opacity: 1 }}
                        whileHover={{ scale: 1.2 }}
                        transition={{ duration: 0.5, type: 'tween' }}
                        className= "app__profile-item"
                        key={about.order}
                    >
                        <img src={urlFor(about.imgUrl)} alt={about.title} />
                        <h2 className="bold-text" style={{ marginTop:20 }}>{about.title}</h2>
                        <p className="p-text" style={{ marginTop:10 }}>{about.description}</p>

                    </motion.div>
                )}
            </div>


        </div >
    )
}

export default AppWrap(
    MotionWrap(About,'app__about'), 
    'about',
    "app__whitebg"
    
);
