import React,{ useState } from 'react'

import { images } from '../../constants'
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';

import './Footer.scss';

const Footer = () => {

    const [formData, setFormData] = useState({name: '', email: '', message: ''});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [loading, setLoading] = useState(false);


    const {name, email, message} = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value})
    }

    const handleSubmit = () =>{
        setLoading(true);

        const contact ={
            _type: 'contact',
            name: name,
            email: email,
            message: message
        }

        client.create(contact)
        .then(() =>{
            setLoading(false);
            setIsFormSubmitted(true);
        })
    }

    //const [name, setName] = useState([]);
    //const [email setEmail] = useState([]);


    /*const handleChangeInput() => (){
        
    }*/

    return (
        <>
            <h2 className="head-text">Let's have coffee & chat</h2>
            <div className="app__footer-cards">
                <div className="app__footer-card">
                    <img src={images.email} alt="email"/>
                    <a href="mailto:nickb.tao@gmail.com" className="p-text">
                        nickb.tao@gmail.com
                    </a>
                </div>
                <div className="app__footer-card">
                    <img src={images.mobile} alt="mobile"/>
                    <a href="tel:+1 (514) 475-4202" className="p-text">
                        (514) 475-4202
                    </a>
                </div>
            </div>
            {/* 
            {!isFormSubmitted ? 
                <div className="app__footer-form app__flex">
                    <div className="app__flex">
                        <input className ="p-text" type="text" name="name" placeholder="Your Name" value={name} onChange={handleChangeInput}/>
                    </div>
                    <div className="app__flex">
                        <input className ="p-text" type="email" name="email" placeholder="Your Email" value={email} onChange={handleChangeInput}/>
                    </div>
                    <div>
                        <textarea 
                            className="p-text app__flex"
                            name="message"
                            placeholder="Your Message"
                            value={message}
                            onChange={handleChangeInput}
                        />
                    </div>

                    <button type="button" className="p-text" onClick={handleSubmit}>{loading ? 'SENDING' : 'Send Message' }</button>
                </div>
                :
                <div>
                    <h3 className="head-text">Thank you for getting in touch!</h3>
                </div>
            }
            */}
        </>
    )
}

export default AppWrap(
    MotionWrap(Footer, 'app__footer'),
    'contact',
    'app__whitebg'

)
