import React, {useState, useEffect} from 'react';


import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';


const Skills = () => {

    const [skills, setSkills] = useState([]);
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        const expQuery = '*[_type =="experiences"]';
        const skillsQuery = '*[_type =="skills"]';

        client.fetch(skillsQuery)
            .then((data) => {
                setSkills(data);
            })
        client.fetch(expQuery)
            .then((data) => {
                //console.log(data);
                setExperiences(data);
            })
    }, [])


    return (
        <div>
            <h2 className="head-text">
                Skills & Activity
            </h2>
            <div className="app__skills-container">
                <motion.div className="app__skills-list">
                    {skills.sort((a,b) => a.order - b.order).map((skill) => (
                        <motion.div 
                            whileInView={{opacity: [0,1]}}
                            transition={{ duration: 0.5 }}
                            className="app__skills-item app__flex"
                            key={skill.id}
                        >
                            <div className ="app__flex" style={{ backgroundColor:skill.bgColor }} >
                                <img src={urlFor(skill.icon)} alt={skill.name}/>
                            </div>
                            <p className="p-text">{skill.name}</p>

                        </motion.div>

                    ))}
                </motion.div>

                <motion.div className="app__skills-exp">
                    {console.log('here!',experiences)}

                    {experiences.sort((a,b) => a.order - b.order).map((experience) =>(
                        <motion.div
                            className ="app__skills-exp-item"
                            key={experience.year}
                        >
                            <div className ="app__skills-exp-year">
                                <p className ="bold-text">{experience.year}</p>
                            </div>
                            <motion.div
                                className="app__skills-exp-works"
                            >
                                {experience.works.sort((a,b) => b.order - a.order).map((work) =>(
                                    <div>
                                        <motion.div
                                            whileInView={{opacity: [0,1]}}
                                            transition={{ duration: 0.5 }}
                                            className="app__skills-exp-work"
                                            data-tip
                                            data-for={work.name}
                                            key={work.order}
                                        >
                                            <h4 className="bold-text">
                                                { work.iseducation ?  <span style={{color:'orchid'}}> {work.name} </span> : work.name}
                                                <span className=""> { work.company ? '@ ' + work.company : '' }</span>
                                                
                                            </h4>

                                            <p className="p-text">
                                             {work.engagement} | { work.status==='Ongoing' ? <span style={{color:'orange'}}> {work.status}</span> : <span style={{color:'green'}}> {work.status}</span> }
                                            </p>
                                            

                                            <ReactTooltip
                                                id={work.name}
                                                effect="solid"
                                                arrowColor="#fff"
                                                className="skills-tooltip"
                                            >
                                                {work.desc}
                                            </ReactTooltip>

                                        </motion.div>
                                    </div>
                                
                                ))}
                            </motion.div>
                            
                        </motion.div>

                        
                    ))}

                    {/*{experiences?.works?.map((work) =>(
                        <div>
                            <motion.div
                                whileInView={{opacity: [0,1]}}
                                transition={{ duration: 0.5 }}
                                className="app__skills-exp-work"
                                data-tip
                                data-for={work.name}
                                key={work.name}
                            >
                                <h4 className="bold-text">{work.name}</h4>
                                <p className="bold-text">{work.company}</p>

                                <ReactTooltip
                                    id={work.name}
                                    effect="solid"
                                    arrowColor="#fff"
                                    className="skills-tooltip"
                                >
                                    {work.desc}
                                </ReactTooltip>

                            </motion.div>
                        </div>
                    ))}*/}

                </motion.div>
            </div>
        </div>
    )
}

export default AppWrap(
    MotionWrap(Skills, 'app__skills'),
    'skills',
    "app__whitebg"
)
